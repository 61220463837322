import { useEffect, useState } from "react";

type Breakpoint = "mobile" | "tablet" | "desktop";
const breakpointSize = {
  mobile: 768,
  tablet: 1024,
  desktop: 1280,
};
export default function useBreakpoint(breakpoint: Breakpoint) {
  const [match, setMatch] = useState(false);
  useEffect(() => {
    function setMatchValue() {
      setMatch(evalBreakpointMatch(breakpoint, window.innerWidth));
    }
    window.addEventListener("resize", setMatchValue);
    setMatchValue();
    return () => window.removeEventListener("resize", setMatchValue);
  }, [breakpoint]);
  return match;
}

function evalBreakpointMatch(breakpoint: Breakpoint, width: number): boolean {
  return width <= breakpointSize[breakpoint];
}
